const setZoom = {
	image: document.querySelector('.city img'),
	scale: 1,
	interval: "",

	setImageSize() {
		setZoom.image.style.transform = `scale(${setZoom.scale})`
	},

	zoomIn() {
		if (setZoom.scale < 5) {
			setZoom.interval = setInterval(() => {
				if (setZoom.scale < 3) {
					setZoom.scale += 0.1
					setZoom.setImageSize()					
				}
			}, 50)
		} else {
			console.log('Zu gross');
			clearInterval(setZoom.interval)
		}
	},

	zoomOut() {
		while (this.scale > 1) {
				this.scale -= 0.01
		}
	},

	isShiftKeyPressed(e) {
		return e.shiftKey
	},

	handleZoom(event) {
	},

	clearZoomInterval() {
		clearInterval(setZoom.interval)	
	},



	init() {
		this.image.addEventListener('mousedown', setZoom.zoomIn)
		this.image.addEventListener('mouseup', setZoom.clearZoomInterval)
	}
}

//setZoom.init()
const avatar = {
	kopf: '',
	haare: '',
	haut: '',
	augen: '',
	brille: '',
	bart: '',
	mund: '',
	kleidung: '',
}

const selectAvatar = a => {
	console.log(a);
	avatar.kopf = a.dataset.body
	avatar.haare = a.dataset.hair
	avatar.haut = a.dataset.skin
	avatar.augen = a.dataset.eyes
	avatar.brille = a.dataset.glasses
	avatar.bart = a.dataset.beard
	avatar.mund = a.dataset.mouth
	avatar.kleidung = a.dataset.clothes
}

const showSettings = setting => {
	const modal = document.querySelector(`.modal.${setting}`)
	modal.classList.add('active')
}

const closeModal = () => {
	const modals = document.querySelectorAll('.modal')
	modals.forEach(modal => modal.classList.remove('active'))
}

const changeAvatar = (el, part) => {

	const avatarItems = document.querySelectorAll('.avatar__item')
	avatarItems.forEach(i => i.classList.remove('selected'))

	avatar[part] = el.dataset.avatar

	const classes = `${avatar.kopf} ${avatar.haare} ${avatar.haut} ${avatar.augen} ${avatar.brille} ${avatar.bart} ${avatar.mund} ${avatar.kleidung}`

	document.querySelector('.splide__slide.is-active .avatar').className = `avatar ${classes}`
	
	el.classList.add('selected')
	// console.log(currentAvatar.classList );
}


const setupNext = () => {
	const avatarInput = document.getElementById('avatar_input')
	const avatarString = `${avatar.kopf} ${avatar.haare} ${avatar.haut} ${avatar.augen} ${avatar.brille} ${avatar.bart} ${avatar.mund} ${avatar.kleidung}`
	avatarInput.value = avatarString
	setupStep('2')
}


const setupStep = step => {
	const fieldsets = document.querySelectorAll('fieldset')
	fieldsets.forEach(fieldset => {
		fieldset.classList.remove('active')
		if (fieldset.dataset.step === step) {
			fieldset.classList.add('active')
		}
	})

}